<script setup>
import { JPageMenu } from "media-flow-ui";
import { toRefs } from "vue";

const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
});

const { data } = toRefs(props);
</script>

<template>
  <div class="page-menu my-5 container mx-auto" :id="data?.id">
    <JPageMenu
      :menuItems="data.menuData.items"
      :inline="data.menuData.inline"
      :allowButtonScroll="data.menuData.showButtons"
      :generalStyle="data.menuData.style"
      :custom-wrapper="defineNuxtLink({})"
      :blurColor="data.menuData.style.blurColor"
    />
  </div>
</template>

<style></style>
